import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getSessionTokens } from '../helpers/utilities';
import NetworkService from '../helpers/network.service';
import { unauthUser, updateStoreTokens } from './auth.reducer';
import ApiRequest from '../helpers/ApiRequest';
import { getAuthInfo } from './auth.reducer';

// export const postPutProfileMemberships = createAsyncThunk(
//   'auth/postProfileMemberships',
//   (data, thunkAPI) => {
//     const { formData, ...otherData } = data;
//     return ApiRequest.post('/Profiles/', formData, {})(formData, thunkAPI);
//   }
// );

// const postProfileMembershipsPromise = ApiRequest.post(`/ProfileMemberships/bulk_create/`);
// const patchProfileMembershipsPromise = ApiRequest.post(`/ProfileMemberships/bulk_update/`);

export const postPutProfileMemberships = createAsyncThunk(
  'auth/postPutProfileMemberships',
  async (data, thunkAPI) => {
    const { membershipsToPost, membershipsToPatch, membershipsToDelete } = data;

    let postProfileMembershipsPromise,
      patchProfileMembershipsPromise,
      deleteProfileMembershipsPromises = [];

    if (membershipsToPost.memberships.length) {
      postProfileMembershipsPromise = ApiRequest.post(
        '/ProfileMemberships/bulk_create/',
        membershipsToPost,
        {}
      )(membershipsToPost, thunkAPI);
    }

    if (membershipsToPatch.memberships.length) {
      patchProfileMembershipsPromise = ApiRequest.post(
        '/ProfileMemberships/bulk_update/',
        membershipsToPatch,
        {}
      )(membershipsToPatch, thunkAPI);
    }
    if (membershipsToDelete.memberships.length) {
      for (let membership of membershipsToDelete.memberships) {
        deleteProfileMembershipsPromises.push(
          ApiRequest.delete(`/ProfileMemberships/${membership.membershipId}/`, {})({}, thunkAPI)
        );
      }
    }

    const response = await axios.all([
      postProfileMembershipsPromise,
      patchProfileMembershipsPromise,
      ...deleteProfileMembershipsPromises,
    ]);
    return response;
  }
);

export const postPutBulkProfileMemberships = createAsyncThunk(
  'auth/postPutProfileMemberships',
  async (data, thunkAPI) => {
    return ApiRequest.post('/ProfileMemberships/bulk_update_partial/', data, {})(data, thunkAPI);
  }
);

export const postProfileMemberships = createAsyncThunk(
  'auth/postProfileMemberships',
  ApiRequest.post('/ProfileMemberships/bulk_create/')
);

export const patchProfileMemberships = createAsyncThunk(
  'auth/patchProfileMemberships',
  ApiRequest.post('/ProfileMemberships/bulk_update/')
);

export const postProfile = createAsyncThunk('auth/postProfile', (data, thunkAPI) => {
  const { formData, ...otherData } = data;
  if (formData)
    return ApiRequest.post('/Profiles/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })(formData, thunkAPI);
});

export const putProfile = createAsyncThunk('auth/putProfile', async (data, thunkAPI) => {
  const { id, ...body } = data;
  const response = await axios.put(`/Profiles/${id}/`, body);
  return response.data;
});

// replace axios with ApiRequest
export const patchProfile = createAsyncThunk('auth/patchProfile', async (data, thunkAPI) => {
  const { id, formData, ...body } = data;
  return id && formData
    ? await ApiRequest.patch(`/Profiles/${id}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })(formData, thunkAPI)
    : await ApiRequest.patch(`/Profiles/${id}/`, body)(formData, thunkAPI);
});

export const deleteProfile = createAsyncThunk('auth/deleteProfile', async (data, thunkAPI) => {
  const { id } = data;
  const response = await axios.delete(`/Profiles/${id}/`);
  return response.data;
});

export const getProfiles = getAuthInfo;
