import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import jwt from 'jsonwebtoken';
import { RequestStatus } from '../helpers/RequestStatus';
import { deleteSessionTokens, getSessionTokens, saveSessionTokens } from '../helpers/utilities';
import NetworkService from '../helpers/network.service';
import ApiRequest, { showErrorMessagesForAction } from '../helpers/ApiRequest';
import { defaultProfileType } from '../constants';
import axios from 'axios';
import { toast } from 'react-toastify';

export const signIn = createAsyncThunk('auth/signIn', async (data, thunkAPI) => {
  return ApiRequest.post('/Users/jwt-token/', data, {
    headers: {},
  })(data, thunkAPI);
});

export const sendActivationEmail = createAsyncThunk(
  'auth/sendActivationEmail',
  ApiRequest.post('/Users/send_activation_email/')
);

export const signInWithGoogle = createAsyncThunk(
  'auth/singInGoogle',
  ApiRequest.post('/Users/jwt-token/social/')
);

export const signInWithFacebook = createAsyncThunk(
  'auth/signInFb',
  ApiRequest.post('/Users/jwt-token/social/')
);

export const verifyEmail = createAsyncThunk(
  'auth/verifyEmail',
  ApiRequest.post('/Profiles/verify_email_address_request/')
);

export const verifyPhone = createAsyncThunk(
  'auth/verifyPhone',
  ApiRequest.post('/Profiles/verify_phone_number_request/')
);

export const recoverPhone = createAsyncThunk(
  'auth/remindPhone',
  ApiRequest.post('/Users/remind_phone_number/')
);

export const signUp = createAsyncThunk('auth/signUp', ApiRequest.post('/Users/'));

export const getAuthInfo = createAsyncThunk('auth/getAuthInfo', async (data, thunkAPI) => {
  const { userId } = data;
  const response = await axios.get(`/Profiles/?user=${userId}&limit=99999`);
  return response.data;
});

export const syncTokenWithStore = createAsyncThunk(
  'auth/syncTokenWithStore',
  async (data, thunkAPI) => {
    const { dispatch } = thunkAPI;
    let tokens = getSessionTokens();
    if (
      !tokens ||
      !tokens.sessionToken ||
      !tokens.refreshToken ||
      tokens.sessionToken === 'null' ||
      tokens.refreshToken === 'null'
    ) {
      dispatch(unauthUser());
    } else {
      NetworkService.syncHeaderTokenWithStorage();
      dispatch(
        updateStoreTokens({
          sessionToken: tokens.sessionToken,
          refreshToken: tokens.refreshToken,
          jwtData: tokens.jwtData,
        })
      );
    }
  }
);

export const activateAccount = createAsyncThunk(
  'auth/activateAccountEmail',
  async (data, thunkAPI) => {
    const { token } = data;
    const response = await axios.get(`/Users/activate_account/${token}/`);
    return response.data;
  }
);

export const verifyEmailConfirmation = createAsyncThunk(
  'auth/verifyEmailConfirm',
  async (data, thunkAPI) => {
    const { token } = data;
    const response = await axios.get(`/Profiles/verify_email_address/${token}/`);
    return response.data;
  }
);

export const verifyPhoneConfirmation = createAsyncThunk(
  'auth/verifyPhoneConfirm',
  ApiRequest.post('/Profiles/verify_phone_number/')
);

export const resetPassword = createAsyncThunk(
  'auth/resetPasswordReq',
  ApiRequest.post('/Users/reset_password_request/')
);

export const resetPasswordConfirmation = createAsyncThunk(
  'auth/resetPasswordConfirm',
  ApiRequest.post('/Users/reset_password/')
);

const onLogout = (state) => {
  state.user = {};
  state.sessionToken = null;
  state.refreshToken = null;
  saveSessionTokens({ sessionToken: null, refreshToken: null, jwtData: null });
  NetworkService.resetDefaultHeaders();
};

const slice = createSlice({
  name: 'auth',
  initialState: {
    // tokens here are used mostly to check if they were synced
    sessionToken: null,
    refreshToken: null,
    loginFetchStatus: null,
    registerFetchStatus: null,
    loginErrorMessages: null,
    registerErrorMessages: null,
    resetErrorMessages: null,
    userFetchStatus: null,
    verifyEmailFetchStatus: null,
    verifyPhoneFetchStatus: null,
    resetFetchStatus: null,
    user: {
      jwtData: null,
      mainProfile: null,
      profiles: null,
    },
  },
  reducers: {
    unauthUser(state, action) {
      deleteSessionTokens();
      NetworkService.resetDefaultHeaders();
      state.sessionToken = null;
      state.refreshToken = null;
      state.user = {};
      state.loginErrorMessages = action.payload;
    },
    updateStoreTokens(state, action) {
      const { sessionToken, refreshToken, jwtData } = action.payload;
      state.sessionToken = sessionToken;
      state.refreshToken = refreshToken;
      state.user.jwtData = jwtData;
    },
    updateVerifyEmailFetchStatus(state, action) {
      state.verifyEmailFetchStatus = action.payload;
    },
    updateVerifyPhoneFetchStatus(state, action) {
      state.verifyPhoneFetchStatus = action.payload;
    },
    updateResetFetchStatus(state, action) {
      state.resetFetchStatus = action.payload;
    },
  },
  // don't have actions that can be exported, only respond to other
  extraReducers: (builder) => {
    builder.addCase(signIn.pending, (state, action) => {
      state.loginFetchStatus = RequestStatus.status.FETCHING;
    });
    builder.addCase(signIn.fulfilled, (state, action) => {
      state.loginFetchStatus = RequestStatus.status.DONE;
      state.sessionToken = action.payload?.access;
      state.refreshToken = action.payload?.refresh;
      state.loginErrorMessages = null;
      state.user.jwtData = jwt.decode(action.payload?.access);
      saveSessionTokens({
        sessionToken: action.payload?.access,
        refreshToken: action.payload?.refresh,
        jwtData: jwt.decode(action.payload?.access),
      });
      NetworkService.setupDefaultHeaders(action.payload?.access);
      // NetworkService.syncHeaderTokenWithStorage();
    });
    builder.addCase(signIn.rejected, (state, action) => {
      state.loginFetchStatus = RequestStatus.status.ERROR;
      state.loginErrorMessages = action.payload;
      state.isAccountActivated = action.payload.status === 403 ? false : true;
      showErrorMessagesForAction(action);
    });
    builder.addCase(sendActivationEmail.pending, (state, action) => {
      state.sendActivationEmailStatus = RequestStatus.status.FETCHING;
    });
    builder.addCase(sendActivationEmail.fulfilled, (state, action) => {
      state.sendActivationEmailStatus = RequestStatus.status.DONE;
    });
    builder.addCase(sendActivationEmail.rejected, (state, action) => {
      state.sendActivationEmailStatus = RequestStatus.status.ERROR;
    });
    builder.addCase(signInWithFacebook.pending, (state, action) => {
      state.loginFetchStatus = RequestStatus.status.FETCHING;
    });
    builder.addCase(signInWithFacebook.fulfilled, (state, action) => {
      state.loginFetchStatus = RequestStatus.status.DONE;
      state.sessionToken = action.payload?.access;
      state.refreshToken = action.payload?.refresh;
      state.loginErrorMessages = null;
      state.user.jwtData = jwt.decode(action.payload?.access);
      saveSessionTokens({
        sessionToken: action.payload?.access,
        refreshToken: action.payload?.refresh,
        jwtData: jwt.decode(action.payload?.access),
      });
      NetworkService.setupDefaultHeaders(action.payload?.access);
      // NetworkService.syncHeaderTokenWithStorage();
    });
    builder.addCase(signInWithFacebook.rejected, (state, action) => {
      state.loginFetchStatus = RequestStatus.status.ERROR;
      state.loginErrorMessages = action.payload;
      showErrorMessagesForAction(action);
    });
    builder.addCase(signInWithGoogle.rejected, (state, action) => {
      state.loginFetchStatus = RequestStatus.status.ERROR;
      state.loginErrorMessages = action.payload;
      showErrorMessagesForAction(action);
    });
    builder.addCase(signInWithGoogle.pending, (state, action) => {
      state.loginFetchStatus = RequestStatus.status.FETCHING;
    });
    builder.addCase(signInWithGoogle.fulfilled, (state, action) => {
      state.loginFetchStatus = RequestStatus.status.DONE;
      state.sessionToken = action.payload?.access;
      state.refreshToken = action.payload?.refresh;
      state.loginErrorMessages = null;
      state.user.jwtData = jwt.decode(action.payload?.access);
      saveSessionTokens({
        sessionToken: action.payload?.access,
        refreshToken: action.payload?.refresh,
        jwtData: jwt.decode(action.payload?.access),
      });
      NetworkService.setupDefaultHeaders(action.payload?.access);
    });
    // builder.addCase(signOut.pending, (state, action) => {
    //   state.loginFetchStatus = RequestStatus.status.FETCHING;
    // })
    // builder.addCase(signOut.fulfilled, (state, action) => {
    //   state.loginFetchStatus = RequestStatus.status.DONE;
    //   onLogout(state);
    // })
    // builder.addCase(signOut.rejected, (state, action) => {
    //   state.loginFetchStatus = RequestStatus.status.ERROR;
    //   state.loginErrorMessages = action.payload;
    //   onLogout(state);
    // })
    builder.addCase(getAuthInfo.pending, (state, action) => {
      state.userFetchStatus = RequestStatus.status.FETCHING;
    });
    builder.addCase(getAuthInfo.fulfilled, (state, action) => {
      state.user.profiles = action.payload.results;
      if (action.payload.results?.length > 0) {
        const defaultProfile = action.payload.results.find(
          (profile) => profile.profile_type === defaultProfileType
        );
        if (defaultProfile) state.user.mainProfile = defaultProfile;
      }
      state.userFetchStatus = RequestStatus.status.DONE;
    });
    builder.addCase(getAuthInfo.rejected, (state, action) => {
      console.error(action);
      state.userFetchStatus = RequestStatus.status.ERROR;
    });
    builder.addCase(signUp.pending, (state, action) => {
      state.registerFetchStatus = RequestStatus.status.FETCHING;
    });
    builder.addCase(signUp.fulfilled, (state, action) => {
      state.registerFetchStatus = RequestStatus.status.DONE;
      state.registerErrorMessages = null;
    });
    builder.addCase(signUp.rejected, (state, action) => {
      state.registerFetchStatus = RequestStatus.status.ERROR;
      state.isAccountActivated = action.payload.status === 403 ? false : true;
      state.registerErrorMessages = action.payload;
    });
    builder.addCase(verifyEmail.pending, (state, action) => {
      state.verifyEmailFetchStatus = RequestStatus.status.FETCHING;
    });
    builder.addCase(verifyEmail.fulfilled, (state, action) => {
      state.verifyEmailFetchStatus = RequestStatus.status.DONE;
      toast.success('Wysłano wiadomość weryfikacyjną');
    });
    builder.addCase(verifyEmail.rejected, (state, action) => {
      state.verifyEmailFetchStatus = RequestStatus.status.ERROR;
    });
    builder.addCase(verifyPhone.pending, (state, action) => {
      state.verifyPhoneFetchStatus = RequestStatus.status.FETCHING;
    });
    builder.addCase(verifyPhone.fulfilled, (state, action) => {
      state.verifyPhoneFetchStatus = RequestStatus.status.DONE;
      toast.success('Wysłano wiadomość weryfikacyjną');
    });
    builder.addCase(verifyPhone.rejected, (state, action) => {
      state.verifyPhoneFetchStatus = RequestStatus.status.ERROR;
    });
    builder.addCase(activateAccount.pending, (state, action) => {
      state.verifyEmailFetchStatus = RequestStatus.status.FETCHING;
    });
    builder.addCase(activateAccount.fulfilled, (state, action) => {
      state.verifyEmailFetchStatus = RequestStatus.status.DONE;
      toast.success('Zweryfikowano pomyślnie');
    });
    builder.addCase(activateAccount.rejected, (state, action) => {
      state.verifyEmailFetchStatus = RequestStatus.status.ERROR;
    });
    builder.addCase(verifyEmailConfirmation.pending, (state, action) => {
      state.verifyEmailFetchStatus = RequestStatus.status.FETCHING;
    });
    builder.addCase(verifyEmailConfirmation.fulfilled, (state, action) => {
      state.verifyEmailFetchStatus = RequestStatus.status.DONE;
      toast.success('Zweryfikowano pomyślnie');
    });
    builder.addCase(verifyEmailConfirmation.rejected, (state, action) => {
      state.verifyEmailFetchStatus = RequestStatus.status.ERROR;
    });
    builder.addCase(verifyPhoneConfirmation.pending, (state, action) => {
      state.verifyPhoneFetchStatus = RequestStatus.status.FETCHING;
    });
    builder.addCase(verifyPhoneConfirmation.fulfilled, (state, action) => {
      state.verifyPhoneFetchStatus = RequestStatus.status.DONE;
      toast.success('Zweryfikowano pomyślnie');
    });
    builder.addCase(verifyPhoneConfirmation.rejected, (state, action) => {
      state.verifyPhoneFetchStatus = RequestStatus.status.ERROR;
    });
    builder.addCase(resetPassword.pending, (state, action) => {
      state.resetFetchStatus = RequestStatus.status.FETCHING;
      state.resetErrorMessages = null;
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.resetFetchStatus = RequestStatus.status.DONE;
      state.resetErrorMessages = null;
      toast.success('Wysłano link resetowania hasła');
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.resetFetchStatus = RequestStatus.status.ERROR;
      state.resetErrorMessages = action.payload;
    });
    builder.addCase(resetPasswordConfirmation.pending, (state, action) => {
      state.resetFetchStatus = RequestStatus.status.FETCHING;
      state.resetErrorMessages = null;
    });
    builder.addCase(resetPasswordConfirmation.fulfilled, (state, action) => {
      state.resetFetchStatus = RequestStatus.status.DONE;
      state.resetErrorMessages = null;
      toast.success('Zresetowano pomyślnie');
    });
    builder.addCase(resetPasswordConfirmation.rejected, (state, action) => {
      state.resetFetchStatus = RequestStatus.status.ERROR;
      state.resetErrorMessages = action.payload;
    });
    builder.addCase(recoverPhone.pending, (state, action) => {
      state.resetFetchStatus = RequestStatus.status.FETCHING;
      state.resetErrorMessages = null;
    });
    builder.addCase(recoverPhone.fulfilled, (state, action) => {
      state.resetFetchStatus = RequestStatus.status.DONE;
      state.resetErrorMessages = null;
      toast.success('Zweryfikowano pomyślnie');
    });
    builder.addCase(recoverPhone.rejected, (state, action) => {
      state.resetFetchStatus = RequestStatus.status.ERROR;
      state.resetErrorMessages = action.payload;
    });
  },
});

export const {
  unauthUser,
  updateStoreTokens,
  updateVerifyEmailFetchStatus,
  updateVerifyPhoneFetchStatus,
  updateResetFetchStatus,
} = slice.actions;

export default slice.reducer;
