import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../helpers/RequestStatus';
import {
  checkInvestmentAgreementAcceptation, downloadInvestmentAgreementsZipped,
  downloadInvestmentDocument, getInvestmentAgreements,
  getInvestmentCalculation,
  getInvestmentChartBalanceForecasts,
  getInvestmentChartBalanceValuations,
  getInvestmentDetails,
  getInvestmentDocuments,
  getInvestmentMessages,
  getInvestmentsList, investmentAgreementAcceptation,
} from './investments.actions';

const slice = createSlice({
  name: 'investments',
  initialState: {
    fetchStatus: null,
    errorMessages: null,
    list: null,
    details: {
      investment: null,
      documents: null,
      messages: null,
      forecasts: null,
      valuations: null,
      calculation: null,
      agreements: null,
      agreementsStatuses: null
    },
  },
  reducers: {
    clearErrorMessages(state, action) {
      state.errorMessages = null;
    },
    clearErrorStatus(state, action) {
      state.errorMessages = {
        ...state.errorMessages,
        status: null
      };
    }
  },
  // don't have actions that can be exported, only respond to other
  extraReducers: {
    [getInvestmentsList.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getInvestmentsList.fulfilled]: (state, action) => {
      state.list = action.payload.results;
      state.fetchStatus = RequestStatus.status.DONE;
    },
    [getInvestmentsList.rejected]: (state, action) => {
      state.errorMessages = action.payload;
      state.fetchStatus = RequestStatus.status.ERROR;
    },
    [getInvestmentDetails.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getInvestmentDetails.fulfilled]: (state, action) => {
      state.details.investment = action.payload;
      state.fetchStatus = RequestStatus.status.DONE;
    },
    [getInvestmentDetails.rejected]: (state, action) => {
      state.errorMessages = action.payload;
      state.fetchStatus = RequestStatus.status.ERROR;
    },
    [getInvestmentMessages.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getInvestmentMessages.fulfilled]: (state, action) => {
      state.details.messages = action.payload;
      state.fetchStatus = RequestStatus.status.DONE;
    },
    [getInvestmentMessages.rejected]: (state, action) => {
      state.errorMessages = action.payload;
      state.fetchStatus = RequestStatus.status.ERROR;
    },
    [getInvestmentDocuments.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getInvestmentDocuments.fulfilled]: (state, action) => {
      state.details.documents = action.payload;
      state.fetchStatus = RequestStatus.status.DONE;
    },
    [getInvestmentDocuments.rejected]: (state, action) => {
      state.errorMessages = action.payload;
      state.fetchStatus = RequestStatus.status.ERROR;
    },
    [getInvestmentAgreements.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getInvestmentAgreements.fulfilled]: (state, action) => {
      state.details.agreements = action.payload;
      state.fetchStatus = RequestStatus.status.DONE;
    },
    [getInvestmentAgreements.rejected]: (state, action) => {
      state.errorMessages = action.payload;
      state.fetchStatus = RequestStatus.status.ERROR;
    },
    [getInvestmentChartBalanceForecasts.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getInvestmentChartBalanceForecasts.fulfilled]: (state, action) => {
      state.details.forecasts = action.payload;
      state.fetchStatus = RequestStatus.status.DONE;
    },
    [getInvestmentChartBalanceForecasts.rejected]: (state, action) => {
      state.errorMessages = action.payload;
      state.fetchStatus = RequestStatus.status.ERROR;
    },
    [getInvestmentChartBalanceValuations.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getInvestmentChartBalanceValuations.fulfilled]: (state, action) => {
      state.details.valuations = action.payload;
      state.fetchStatus = RequestStatus.status.DONE;
    },
    [getInvestmentChartBalanceValuations.rejected]: (state, action) => {
      state.errorMessages = action.payload;
      state.fetchStatus = RequestStatus.status.ERROR;
    },
    [getInvestmentCalculation.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getInvestmentCalculation.fulfilled]: (state, action) => {
      state.details.calculation = action.payload;
      state.fetchStatus = RequestStatus.status.DONE;
    },
    [getInvestmentCalculation.rejected]: (state, action) => {
      state.errorMessages = action.payload;
      state.fetchStatus = RequestStatus.status.ERROR;
      state.details.calculation = null;
    },
    [downloadInvestmentDocument.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.status.FETCHING;
    },
    [downloadInvestmentDocument.fulfilled]: (state, action) => {
      state.fetchStatus = RequestStatus.status.DONE;
    },
    [downloadInvestmentDocument.rejected]: (state, action) => {
      state.errorMessages = action.payload;
      state.fetchStatus = RequestStatus.status.ERROR;
    },
    [investmentAgreementAcceptation.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.status.FETCHING;
    },
    [investmentAgreementAcceptation.fulfilled]: (state, action) => {
      state.fetchStatus = RequestStatus.status.DONE;
    },
    [investmentAgreementAcceptation.rejected]: (state, action) => {
      state.errorMessages = action.payload;
      state.fetchStatus = RequestStatus.status.ERROR;
    },
    [checkInvestmentAgreementAcceptation.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.status.FETCHING;
    },
    [checkInvestmentAgreementAcceptation.fulfilled]: (state, action) => {
      state.details.agreementsStatuses = action.payload;
      state.fetchStatus = RequestStatus.status.DONE;
    },
    [checkInvestmentAgreementAcceptation.rejected]: (state, action) => {
      state.errorMessages = action.payload;
      state.fetchStatus = RequestStatus.status.ERROR;
    },
    [downloadInvestmentAgreementsZipped.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.status.FETCHING;
    },
    [downloadInvestmentAgreementsZipped.fulfilled]: (state, action) => {
      state.fetchStatus = RequestStatus.status.DONE;
    },
    [downloadInvestmentAgreementsZipped.rejected]: (state, action) => {
      state.errorMessages = action.payload;
      state.fetchStatus = RequestStatus.status.ERROR;
    },
  },
});

export const {
  clearErrorMessages,
  clearErrorStatus
} = slice.actions;

export default slice.reducer;
