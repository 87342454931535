import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../helpers/RequestStatus';
import { getAppConfigs, getMenuBadges } from './config.actions';

const slice = createSlice({
  name: 'config',
  initialState: {
    fetchStatus: null,
    dropdownChoices: {
      membershipTypeChoices: [],
      companyTypeChoices: [],
    },
    constants: {
      currencies: [],
    },
    menuBadges: {},
  },
  reducers: {
    updateConfigFetchStatus(state, action) {
      state.buildingsFetchStatus = action.payload;
    },
  },
  // don't have actions that can be exported, only respond to other
  extraReducers: {
    [getAppConfigs.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getAppConfigs.fulfilled]: (state, action) => {
      const currencies = action.payload[0].results;
      const memebershipTypes = action.payload[1];
      const companyTypes = action.payload[2];

      state.constants = { currencies };
      state.dropdownChoices.membershipTypeChoices = memebershipTypes.map((item) => {
        const [value, label] = Object.entries(item)[0];
        return { value, label };
      });
      state.dropdownChoices.companyTypeChoices = companyTypes.map((item) => {
        const [value, label] = Object.entries(item)[0];
        return { value, label };
      });
      state.fetchStatus = RequestStatus.status.DONE;
    },
    [getAppConfigs.rejected]: (state, action) => {
      state.fetchStatus = RequestStatus.status.ERROR;
      state.errorMessage = action.payload;
    },
    // [getMenuBadges.pending]: (state, action) => {
    //   state.fetchStatus = RequestStatus.status.FETCHING;
    // },
    // [getMenuBadges.fulfilled]: (state, action) => {
    //   state.menuBadges = action.payload.data;
    //   state.fetchStatus = RequestStatus.status.DONE;
    // },
    // [getMenuBadges.rejected]: (state, action) => {
    //   state.fetchStatus = RequestStatus.status.ERROR;
    //   state.errorMessage = action.payload;
    // },
  },
});

export const { updateConfigFetchStatus } = slice.actions;

export default slice.reducer;
