import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_URL;

// mocked request
export const getMenuBadges = createAsyncThunk('config/menuBadges', async (data, thunkAPI) => {
  const response = await axios.get(`/menu_badges/`);
  return response.data;
});

// also dropdown choices here
export const getAppConfigs = createAsyncThunk('auth/getAppConfigs', async (data, thunkAPI) => {
  const response = await axios.all([
    getCurrenciesPromise(),
    getMembershipTypeChoicesPromise(),
    getCompanyTypeChoicesPromise(),
    getBusinessTypeChoicesPromise(),
  ]);
  return response;
});

// mocked request
// duplicated here because of weird initialization error
export const signOut = createAsyncThunk('auth/signOut', async (data, thunkAPI) => {
  const response = await axios.delete('/auth/sign-out/');
  return response.data;
});

const getCurrenciesPromise = () => axios.get(`/Currencies/`).then((res) => res.data);
const getMembershipTypeChoicesPromise = () =>
  axios.get(`/ProfileMemberships/dict_types/`).then((res) => res.data);
const getCompanyTypeChoicesPromise = () =>
  axios.get(`/Profiles/dict_company_types/`).then((res) => res.data);
const getBusinessTypeChoicesPromise = () =>
  axios.get(`/ProfileMemberships/dict_business_types/`).then((res) => res.data);
