import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { getRoutesForPrivateRoutingComponent } from '../../routes';
import { Redirect, Route, useLocation } from 'react-router-dom';
import AppBar from '../../components/AppBar/AppBar';
import CustomBottomNavigation from './BottomNavigation';
import { theme } from '../../theme';
import MenuDrawer from './MenuDrawer';
import ViewContainer from '../../components/RouteViewContainer';
import AppBarExtension from '../../components/AppBar/AppBarExtension';

export const RouteContent = styled.div`
  padding-left: ${theme.paddings.appPaddingPxVal}px;
  padding-right: ${theme.paddings.appPaddingPxVal}px;
  max-width: ${theme.responsive.maxContentWidthPxVal}px;
  padding-bottom: ${theme.sizes.bottomBarHeightPxVal}px;

  /* padding-bottom: calc(${theme.sizes.bottomBarHeightPxVal}px + 40px); */
  margin: auto;
  font-family: 'Open Sans';
`;

function Layout(props) {
  const { appTitle, history, getMenuBadges, menuBadges, signOut } = props;

  const fullModalRef = useRef(null);
  const modalRef = useRef(null);
  const [openFullModal, setOpenFullModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [bottomNavigationValue, _setBottomNavigationValue] = useState(0);
  const [onAppBarButtonClick, setOnAppBarButtonClick] = useState({
    onClick: () => {},
  });
  const [isMenuDrawerOpen, setIsMenuDrawerOpen] = useState(false);
  const [actionButtons, setActionButtons] = useState(<div></div>);
  const [title, setTitle] = useState('');
  const [showBottomNavigation, setShowBottomNavigation] = useState(true);
  const [appBarExtensionContent, setAppBarExtensionContent] = useState({
    isVisible: false,
    content: <div></div>,
    height: '0px',
  });
  const location = useLocation();

  let routingElements = [];
  routingElements =
    routingElements.length === 0 ? getRoutesForPrivateRoutingComponent() : routingElements;

  const setBottomNavigationValue = ({ value = 0, bottomNavigationRoutes = [], noHistoryPush }) => {
    const selectedOption = bottomNavigationRoutes[value];
    if (selectedOption?.path && !noHistoryPush) history.push(selectedOption.path);
    _setBottomNavigationValue(value);
  };

  // useEffect(() => {
  //   //todo: set bottom bar based on url here
  //   //todo: do both request at once
  //   getMenuBadges();
  //   const fetchBadgesInterval = setInterval(() => {
  //     getMenuBadges();
  //   }, 15 * 1000);
  //
  //   return () => {
  //     clearInterval(fetchBadgesInterval);
  //   };
  // }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location?.pathname]);

  return (
    <>
      {routingElements.map((route, idx) => {
        return route.component ? (
          <Route
            key={idx}
            path={route.path}
            exact={route.exact}
            name={route.name}
            render={(itemProps, idx) => (
              <ViewContainer className="viewContainer">
                <AppBar
                  elevation={0}
                  title={title || appTitle || itemProps?.appBar?.title}
                  onAppBarButtonClick={onAppBarButtonClick}
                  setIsMenuDrawerOpen={setIsMenuDrawerOpen}
                  {...route?.appBar}
                  actionButtons={actionButtons}
                />
                {appBarExtensionContent.isVisible && (
                  <AppBarExtension {...appBarExtensionContent}>
                    {appBarExtensionContent.content}
                  </AppBarExtension>
                )}

                <MenuDrawer
                  isMenuDrawerOpen={isMenuDrawerOpen}
                  setIsMenuDrawerOpen={setIsMenuDrawerOpen}
                  signOut={signOut}
                />
                <RouteContent className="route-content" isBottomMenu={itemProps.isBottomMenu}>
                  <route.component
                    {...itemProps}
                    fullModalRef={fullModalRef}
                    openFullModal={openFullModal}
                    setOpenFullModal={setOpenFullModal}
                    modalRef={modalRef}
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    setOnAppBarButtonClick={setOnAppBarButtonClick}
                    isMenuDrawerOpen={isMenuDrawerOpen}
                    setIsMenuDrawerOpen={setIsMenuDrawerOpen}
                    setAppBarExtensionContent={setAppBarExtensionContent}
                    setActionButtons={setActionButtons}
                    setTitle={setTitle}
                    setShowBottomNavigation={setShowBottomNavigation}
                  />
                </RouteContent>
              </ViewContainer>
            )}
          />
        ) : null;
      })}
      {showBottomNavigation && (
        <CustomBottomNavigation
          bottomNavigationValue={bottomNavigationValue}
          setBottomNavigationValue={setBottomNavigationValue}
          menuBadges={menuBadges}
        />
      )}
    </>
  );
}

export default Layout;
