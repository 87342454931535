import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import auth from './reducers/auth.reducer';
import config from './reducers/config.reducer';
import wallet from './reducers/wallet.reducer';
import investments from './reducers/investments.reducer';
import profiles from './reducers/profiles.reducer';

const reducer = combineReducers({
  auth,
  config,
  wallet,
  investments,
  profiles,
});

const getMiddleware = () => {
  let middleware = [thunk];
  if (process.env.NODE_ENV === 'development') {
    middleware.push(logger);
  }
  return middleware;
};

export default createStore(reducer, applyMiddleware(...getMiddleware()));
