import React, { useEffect } from 'react';
import landingBg from 'assets/images/landingBg.png';
import landingBg2x from 'assets/images/landingBg@2x.png';
import landingBg3x from 'assets/images/landingBg@3x.png';
import LogoPlacidloColoured from 'assets/svg/placidlo_logo_color.svg';
import { ButtonLink, SocialMediaButton, Button } from '../../components/Button';
import { getRegisterPath, getLoginPath, getWalletPath } from '../../routes';
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons';
import GoogleLogin from 'react-google-login';
import styled from 'styled-components';
import theme from '../../theme';

const Landing = (props) => {
  const {
    signInWithFacebook,
    signInWithGoogle,
    getAuthInfo,
    getAppConfigs,
    auth,
    areConstantsLoaded,
    areAuthInfoLoaded,
  } = props;

  let history = useHistory();

  const handleFBLoginClick = (e) => {
    e.preventDefault();
    if (!window.FB) {
      console.error('Import FB failed');
      return;
    }

    window.FB.getLoginStatus(function (statusResponse) {
      if (statusResponse.status === 'connected') {
        responseFacebook(statusResponse.authResponse);
      } else {
        window.FB.login(function (loginResponse) {
          if (loginResponse.authResponse) {
            responseFacebook(loginResponse.authResponse);
          } else {
            console.log('User cancelled login or did not fully authorize.');
          }
        });
      }
    });
  };

  const responseGoogle = (response) => {
    signInWithGoogle({
      provider: 'google-oauth2',
      access_token: response?.accessToken,
    });
  };

  const responseFacebook = (response) => {
    signInWithFacebook({ provider: response?.graphDomain, access_token: response?.accessToken });
  };

  useEffect(() => {
    if (auth.sessionToken && auth.user?.jwtData?.user_id) {
      getAppConfigs && getAppConfigs();
      getAuthInfo && getAuthInfo({ userId: auth.user?.jwtData?.user_id });
    }
  }, [auth.sessionToken]);

  useEffect(() => {
    if (auth.sessionToken && areConstantsLoaded && areAuthInfoLoaded)
      history.push(`${getWalletPath()}`);
  });

  const StyledWrapper = styled.div`
    height: 100vh;
    background: url(${landingBg});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    display: flex;
    flex-direction: column;

    @media (min-width: ${theme.devices.laptop}) {
      background: url(${landingBg2x});
      background-position: top center;
      background-size: 150% auto;
    }

    @media (min-width: ${theme.devices.desktop}) {
      background: url(${landingBg3x});
      background-position: top center;
      background-size: 150% auto;
    }
  `;

  return (
    <StyledWrapper>
      <img src={LogoPlacidloColoured} className="mx-auto mt-24" alt="logo" />

      <div className="flex flex-col mx-auto mt-auto mb-32 w-96">
        <ButtonLink maxWidth="300px" to={getRegisterPath()}>
          Zarejestruj się
        </ButtonLink>
        <ButtonLink maxWidth="300px" to={getLoginPath()}>
          Zaloguj się
        </ButtonLink>
        <SocialMediaButton onClick={handleFBLoginClick}>
          <FontAwesomeIcon icon={faFacebook} size="lg" />
          <span className="text">Zaloguj przez Facebook</span>
        </SocialMediaButton>
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE}
          render={(renderProps) => (
            <SocialMediaButton onClick={renderProps.onClick}>
              <FontAwesomeIcon icon={faGoogle} size="lg" />
              <span className="text">Zaloguj przez Google</span>
            </SocialMediaButton>
          )}
          onSuccess={responseGoogle}
          onFailure={(data) => {
            console.log('User cancelled login or did not fully authorize.');
            console.log(data);
          }}
          cookiePolicy={'single_host_origin'}
        />
      </div>
    </StyledWrapper>
  );
};

export default Landing;
