import { connect } from 'react-redux';
import SyncApp from './SyncApp';
import { getAuthInfo, syncTokenWithStore } from '../../reducers/auth.reducer';
import { getAppConfigs } from '../../reducers/config.actions';

function mapStateToProps(state, otherProps) {
  return {
    token: state.auth.sessionToken,
    fetchStatus: state.auth.loginFetchStatus,
    userId: state.auth.user?.jwtData?.user_id
  };
}

export default connect(mapStateToProps, { syncTokenWithStore, getAuthInfo, getAppConfigs })(
  SyncApp
);
