import React from 'react';
import styled from 'styled-components';
import { theme } from '../../theme';

const AppBarExtensionCurveWrapper = styled.div`
  background: transparent;
  overflow: hidden;
  width: 100%;
  height: 100px;
  position: relative;

  @media (max-width: ${theme.devices.mobileM}) {
    height: 50px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: -100px;
    right: -100px;
    height: ${({ top }) => top}%;
    background: ${theme.colors.yellow};
    z-index: -1;
    border-radius: 0 0 50% 50% / 0 0 100% 100%;
  }
`;

const AppBarExtensionCurveContent = styled.div`
  max-width: ${theme.responsive.maxContentWidthPxVal}px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AppBarExtensionHeight = styled.div`
  background-color: ${theme.colors.yellow};
  height: ${(props) => (props.height ? props.height : '0px')};
  position: relative;
`;

const ChildrenContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0);
  padding-top: 8px;
`;

const AppBarExtensionWrapper = styled.div`
  position: relative;
  font-family: 'Open Sans';
`;

const AppBarExtension = (props) => {
  const { children, height = 20, top = '50', displayCurveWrapper = true } = props;
  return (
    <AppBarExtensionWrapper className="wrapper">
      <AppBarExtensionHeight height={height} />
      {displayCurveWrapper && (
        <AppBarExtensionCurveWrapper top={top} className="AppBarExtension">
          <AppBarExtensionCurveContent>
            <div></div>
          </AppBarExtensionCurveContent>
        </AppBarExtensionCurveWrapper>
      )}

      <ChildrenContainer>{children || <div></div>}</ChildrenContainer>
    </AppBarExtensionWrapper>
  );
};

export default AppBarExtension;
