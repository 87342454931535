import React, { useEffect } from 'react';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import { getRoutesForBottomNavigation } from '../../routes';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import styled from 'styled-components';
import { theme } from '../../theme';
import { Badge } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';

const StyledBadge = withStyles((muiTheme) => ({
  badge: {
    right: -12,
    top: 9,
    border: `2px solid ${theme.colors.yellow}`,
    backgroundColor: theme.colors.yellow,
    padding: '0 4px',
    color: theme.colors.white,
    fontWeight: 700,
    borderRadius: '4px',
  },
}))(Badge);

const BottomNavigationContainer = styled.div`
  position: fixed;
  bottom: 8px;
  left: 8px;
  right: 8px;
  z-index: 9990;
  max-height: ${theme.footer.heightPxVal}px;

  svg *:not(circle) {
    stroke-width: 0;
  }

  .MuiBottomNavigation-root {
    margin-left: auto;
    margin-right: auto;
    border-radius: 12px;
    background-color: ${theme.colors.dark};
    max-width: ${theme.responsive.maxContentWidthPxVal}px;
  }

  .MuiBottomNavigationAction-root {
    padding-top: 6px;
    min-width: 10px !important;
    width: 10px !important;
  }

  .MuiBottomNavigationAction-label {
    color: ${theme.colors.white};
    margin-top: 4px;
    font-size: 1rem;
    font-style: normal;
    line-height: 1rem;
    text-align: center;
    font-weight: 600;
  }

  .Mui-selected {
    svg *:not(circle) {
      fill: ${theme.colors.yellow};
      stroke-width: 0;
    }
    .MuiBottomNavigationAction-label {
      margin-top: 4px;
      font-size: 1rem;
      font-style: normal;
      line-height: 1rem;
      text-align: center;
      font-weight: 600;
      color: ${theme.colors.yellow};
    }
  }

  @media (max-width: ${theme.devices.mobileM}) {
    svg {
      width: 22px;
      height: 22px;
    }

    .MuiBottomNavigationAction-label {
      font-size: 0.8rem;
    }

    .Mui-selected {
      .MuiBottomNavigationAction-label {
        font-size: 0.8rem;
      }
    }
  }
`;

const CustomBottomNavigation = (props) => {
  const { bottomNavigationValue, setBottomNavigationValue, menuBadges } = props;

  let location = useLocation();

  const getBadgeForRoute = (bottomRoute) => {
    if (!bottomRoute.badgeKey) return;
    let nr = 0;
    for (let badgeNrKey of Object.keys(menuBadges)) {
      if (badgeNrKey === bottomRoute.badgeKey) {
        nr = menuBadges[badgeNrKey];
        break;
      }
    }
    return nr;
  };

  useEffect(() => {
    let value = -1;
    const routesBottomNav = getRoutesForBottomNavigation();
    const fullPath = location.pathname;
    for (const routeIdx in routesBottomNav) {
      if (routesBottomNav[routeIdx]?.path === '/') continue;
      else if (
        routesBottomNav.hasOwnProperty(routeIdx) &&
        fullPath.includes(routesBottomNav[routeIdx]?.path)
      ) {
        value = routeIdx;
        break;
      }
    }
    setBottomNavigationValue({
      value: parseInt(value),
      bottomNavigationRoutes: getRoutesForBottomNavigation(),
      noHistoryPush: true,
    });
  }, [location.pathname]);

  return (
    <BottomNavigationContainer>
      <BottomNavigation
        value={bottomNavigationValue}
        onChange={(event, value) =>
          setBottomNavigationValue({
            event,
            value,
            bottomNavigationRoutes: getRoutesForBottomNavigation(),
          })
        }
        showLabels
      >
        {getRoutesForBottomNavigation().map((bottomRoute) => {
          const badgeNr = getBadgeForRoute(bottomRoute);
          return (
            <BottomNavigationAction
              label={<StyledBadge badgeContent={badgeNr}>{bottomRoute.label}</StyledBadge>}
              icon={bottomRoute.icon}
              key={bottomRoute.label}
            />
          );
        })}
      </BottomNavigation>
    </BottomNavigationContainer>
  );
};

export default CustomBottomNavigation;
