import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { getLoginPath } from '../../routes';

const checkPermissionsToRoute = (pathName, roles) => {
  // no roles/permissions at this moment
  return true;
};

const ComponentToAuthorize = (props) => {
  const { component: Component, location } = props;
  return <Component {...props} key={location.state ? location.state.key : null} />;
};

const PrivateRouteToAuthenticate = ({ component, ...otherProps }) => {
  const { token, roles } = otherProps;

  return (
    <Route
      {...otherProps}
      render={(props) =>
        token ? (
          <ComponentToAuthorize component={component} roles={roles} {...props} />
        ) : (
          <>
            <Redirect to={{ pathname: getLoginPath() }} />
          </>
        )
      }
    />
  );
};

export default PrivateRouteToAuthenticate;
