import _ from 'lodash';

export function getSessionTokens() {
  const sessionToken = localStorage.getItem('sessionToken');
  const refreshToken = localStorage.getItem('refreshToken');
  const jwtDataString = localStorage.getItem('jwtData');
  let jwtData;
  try {
    jwtData = JSON.parse(jwtDataString);
  } catch (e) {
    console.error('jwt parsing error', jwtDataString);
    jwtData = {};
  }
  return { sessionToken, refreshToken, jwtData };
}

export function saveSessionTokens({ sessionToken, refreshToken, jwtData }) {
  sessionToken &&
    sessionToken !== 'undefined' &&
    localStorage.setItem('sessionToken', sessionToken);
  refreshToken &&
    refreshToken !== 'undefined' &&
    localStorage.setItem('refreshToken', refreshToken);
  if (jwtData && jwtData.user_id) localStorage.setItem('jwtData', JSON.stringify(jwtData));
}

export function deleteSessionTokens() {
  localStorage.removeItem('sessionToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('user');
  localStorage.removeItem('jwtData');
}

export function getDescendantProp(obj, path) {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj);
}
export const formatMaskedAmountToNumber = (maskedAmount) => {
  return typeof maskedAmount === 'string'
    ? +_.replace(maskedAmount.replace(new RegExp(',', 'g'), ''), '$', '')
    : maskedAmount;
};

export const formatMaskedPercentToNumber = (maskedAmount) =>
  typeof maskedAmount === 'string'
    ? +maskedAmount.replace(new RegExp('%', 'g'), '').replace(new RegExp(',', 'g'), '')
    : maskedAmount;

export const areDevFeatures = () => {
  const parameter = process.env.REACT_APP_DEV_FEATURES;
  return parameter && parameter === 'true';
};

export const reformatPhoneToSubmit = (phoneNr) => {
  return '+1' + phoneNr.replace(new RegExp('-', 'g'), '').replace(new RegExp('_', 'g'), '');
};

export const reformatPhone = (phoneNr) => {
  if (!phoneNr) return null;
  else if (!phoneNr.includes('+1') && phoneNr.includes('-')) return phoneNr;
  let phone = phoneNr.replace('+1', '');
  phone = phone.slice(0, 3) + '-' + phone.slice(3);
  return phone.slice(0, 7) + '-' + phone.slice(7);
};

export const covertObjToQueryString = (data) => {
  let queryParams = new URLSearchParams();
  for (let [key, value] of Object.entries(data)) {
    if (value && !_.isObject(value)) queryParams.set(key, value);
  }
  return queryParams.toString();
};

// response err messages in array
export const getErrorMessageFor = ({ key, errorMessages, inArray = true }) => {
  return (
    errorMessages && errorMessages[key] && (inArray ? errorMessages[key][0] : errorMessages[key])
  );
};
