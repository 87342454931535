import React from 'react';
import loadable from '@loadable/component';
import {
  CoinsIcon,
  InvestmentsIcon,
  NotificationsIcon,
  SafeIcon,
  WalletIcon,
} from './views/Layout/BottomNavigationIcons';
import { formatISO } from 'date-fns';

const Home = loadable(() => import('views/Landing'));
const Intro = loadable(() => import('views/Intro.js'));
const Login = loadable(() => import('views/Login'));
const Register = loadable(() => import('views/Register'));
const EmailVerificationConfirmation = loadable(() =>
  import('views/EmailVerificationConfirmation/index.js')
);
const AccountActivation = loadable(() => import('views/AccountActivation/index.js'));
const PhoneVerificationConfirmation = loadable(() =>
  import('views/PhoneVerificationConfirmation/index.js')
);
const ResetPassword = loadable(() => import('views/ResetPassword/index.js'));
const PhoneRecovery = loadable(() => import('views/PhoneRecovery/index.js'));
const SetNewPassword = loadable(() => import('views/SetNewPassword/index.js'));
const UserSettings = loadable(() => import('views/UserSettings/index.js'));
const InvestmentDetails = loadable(() => import('views/Investments/Details'));
const InvestmentMessages = loadable(() => import('views/Investments/Messages'));
const InvestmentSimulation = loadable(() => import('views/Investments/Simulation'));
const Invest = loadable(() => import('views/Investments/Invest'));
const Profile = loadable(() => import('views/UserProfile/index.js'));
const PolishCompanyProfile = loadable(() => import('views/PolishCompanyProfile/index.js'));
const ForeignCompanyProfile = loadable(() => import('views/ForeignCompanyProfile/index.js'));
const PolishCompanyManagement = loadable(() => import('views/PolishCompanyManagement/index.js'));
const ForeignCompanyManagement = loadable(() => import('views/ForeignCompanyManagement/index.js'));
const Pin = loadable(() => import('views/Pin.js'));
const NewPin = loadable(() => import('views/NewPin.js'));
const Payment = loadable(() => import('views/Payment.js'));
const InvestmentAgreement = loadable(() => import('views/InvestmentAgreement.js'));
const Statue = loadable(() => import('views/Statue.js'));
const NotificationDetails = loadable(() => import('views/NotificationDetails.js'));

//bottom navigation components
const Wallet = loadable(() => import('views/Wallet'));
const AddCoins = loadable(() => import('views/AddCoins.js'));
const Safe = loadable(() => import('views/Safe.js'));
const Investments = loadable(() => import('views/Investments'));
const Notifications = loadable(() => import('views/Notifications.js'));

// account activation components
const ActivationAccount = loadable(() => import('views/Loaders/ActivationAccountLoader.js'));
const ResetPasswordAccount = loadable(() => import('views/Loaders/ResetPasswordAccountLoader.js'));

// account profile components
const CreateProfileWelcomePage = loadable(() =>
  import('views/AccountProfile/CreateProfileWelcomePage.js')
);
const ProfileIdentities = loadable(() => import('views/AccountProfile/ProfileIdentities.js'));
const ProfileIdentitiesCreate = loadable(() =>
  import('views/AccountProfile/ProfileIdentitiesCreate.js')
);
const ProfileIdentitiesEdit = loadable(() =>
  import('views/AccountProfile/ProfileIdentitiesEdit.js')
);

const getPathWithParams = ({ path, params }) => {
  const areSearchParams = params && Object.keys(params).length > 0;
  let searchParams;
  if (areSearchParams) {
    searchParams = new URLSearchParams();
    for (let [key, val] of Object.entries(params)) {
      searchParams.set(key, val);
    }
  }
  return `${path}${areSearchParams ? '?' + searchParams.toString() : ''}`;
};

//bottom navigation routes
export const getWalletPath = () => '/wallet';
export const getAddCoinsPath = () => '/add-coins';
export const getSafePath = () => '/safe';
export const getInvestmentsPath = () => '/investments';
export const getInvestmentDetailsPath = () => '/investments/:id';
export const setInvestmentDetailsPath = (id) => `/investments/${id}`;
export const getInvestmentMessagesPath = () => '/investments/:id/messages';
export const setInvestmentMessagesPath = (id) => `/investments/${id}/messages`;
export const getInvestmentSimulationPath = () => '/investments/:id/simulation';
export const setInvestmentSimulationPath = (id) => `/investments/${id}/simulation`;
export const getInvestPath = () => '/investments/:id/invest';
export const setInvestPath = (id) => `/investments/${id}/invest`;

export const getNotificationsPath = () => '/notifications';
export const getNotificationDetailsPath = () => '/notifications/notification-details/:id';
export const setNotificationDetailsPath = (id) => `/notifications/notification-details/${id}`;

export const getHomePath = () => '/';
export const getIntroPath = () => '/intro';
export const getLoginPath = () => '/login';
export const getRegisterPath = () => '/register';
export const getResetPasswordPath = () => '/reset-password';
export const getSetNewPasswordPath = () => '/account/reset-password/:token';
export const getSetNewPasswordPathAlt = () => '/reset-password/:token';
export const getRecoverPhoneNrPath = () => '/recover-phone';
export const getNewPasswordPath = () => '/new-password';

export const getPinPath = () => '/pin';
export const getNewPinPath = () => '/new-pin';
export const getActivationCodePath = () => '/activation-code';
export const getPaymentPath = () => '/add-coins/payment';
export const getInvestmentAgreementPath = () => '/investment-agreement';
export const getStatuePath = () => '/statue';

//user activate
export const getAccountActivationPath = () => '/account/activation/:token';
export const getAccountActivationPathAlt = () => '/account-activation/:token';
// export const getResetPasswordState = () => '/account/reset-password/:id';

export const getEmailVerificationPath = () => '/profile/verify-email-address/:token';
export const getEmailVerificationPathAlt = () => '/verify-email-address/:token';
export const getPhoneVerificationPath = () => '/profile/verify-phone-number/';

export const getProfilePath = (params) => getPathWithParams({ path: '/profile', params });
export const getPolishCompanyProfilePath = (params) =>
  getPathWithParams({ path: '/polish-company-profile/', params });
export const getPolishCompanyManagementPath = (params) =>
  getPathWithParams({ path: '/polish-company-management/', params });
export const getForeignCompanyProfilePath = (params) =>
  getPathWithParams({ path: '/foreign-company-profile/', params });
export const getForeignCompanyManagementPath = (params) =>
  getPathWithParams({ path: '/foreign-company-management/', params });
export const getSettingsPath = (params) => getPathWithParams({ path: '/settings', params });
//acccount profile ???
export const getCreateProfileWelcomePage = () => '/wallet/create-profile-welcome';
export const getProfileIdentitiesPath = () => '/wallet/profile-identities';
export const getProfileIdentitiesCreate = () => '/wallet/profile-identities-create';
export const getProfileIdentitiesEdit = () => '/wallet/profile-identities-edit';

const privateRoutes = [
  {
    path: `${getWalletPath()}`,
    exact: true,
    component: Wallet,
    isBottomMenu: true,
    label: 'Portfel',
    icon: <WalletIcon />,
    appBar: {
      title: <div>Portfel</div>,
      actionButtons: null,
    },
  },
  {
    path: `${getAddCoinsPath()}`,
    exact: true,
    component: AddCoins,
    isBottomMenu: true,
    label: 'Doładuj',
    icon: <CoinsIcon />,
    appBar: {
      title: <div>Doładuj</div>,
      actionButtons: null,
    },
  },
  {
    path: `${getSafePath()}`,
    exact: true,
    component: Safe,
    isBottomMenu: true,
    label: 'Sejf',
    icon: <SafeIcon />,
    appBar: {
      title: <div>Sejf</div>,
      actionButtons: null,
    },
  },
  {
    path: `${getInvestPath()}`,
    exact: true,
    component: Invest,
    isBottomMenu: false,
    label: 'Inwestuj',
    icon: <InvestmentsIcon />,
    appBar: {
      title: <div>Inwestuj</div>,
      actionButtons: null,
    },
  },
  {
    path: `${getInvestmentSimulationPath()}`,
    exact: true,
    component: InvestmentSimulation,
    isBottomMenu: false,
    label: 'Symulacja inwestycji',
    icon: <InvestmentsIcon />,
    appBar: {
      title: <div>Symulacja inwestycji</div>,
      actionButtons: null,
    },
  },
  {
    path: `${getInvestmentMessagesPath()}`,
    exact: true,
    component: InvestmentMessages,
    isBottomMenu: false,
    label: 'Komunikaty inwestycji',
    icon: <InvestmentsIcon />,
    appBar: {
      actionButtons: null,
    },
  },
  {
    path: `${getInvestmentDetailsPath()}`,
    exact: true,
    component: InvestmentDetails,
    isBottomMenu: false,
    label: 'Inwestycja',
    icon: <InvestmentsIcon />,
    appBar: {
      actionButtons: null,
    },
  },
  {
    path: `${getInvestmentsPath()}`,
    exact: true,
    component: Investments,
    isBottomMenu: true,
    label: 'Inwestycje',
    icon: <InvestmentsIcon />,
    appBar: {
      title: <div>Inwestycje</div>,
      actionButtons: null,
    },
  },
  {
    path: `${getNotificationsPath()}`,
    exact: true,
    component: Notifications,
    isBottomMenu: true,
    label: 'Powiadomienia',
    icon: <NotificationsIcon />,
    appBar: {
      title: <div>Powiadomienia</div>,
      actionButtons: null,
    },
  },
  // {
  //   path: `${getNotificationDetailsPath()}`,
  //   exact: true,
  //   component: NotificationDetails,
  //   isBottomMenu: false,
  // },
  {
    path: `${getProfilePath()}`,
    exact: true,
    component: Profile,
    isBottomMenu: false,
    appBar: {
      title: <div>Dane osobowe</div>,
      actionButtons: null,
    },
  },
  {
    path: `${getPolishCompanyProfilePath()}:id`,
    exact: true,
    component: PolishCompanyProfile,
    isBottomMenu: false,
    appBar: {
      title: <div>Spółka polska</div>,
      actionButtons: null,
    },
  },
  {
    path: `${getPolishCompanyManagementPath()}:id`,
    exact: true,
    component: PolishCompanyManagement,
    isBottomMenu: false,
    appBar: {
      title: <div>Reprezentacja</div>,
      actionButtons: null,
    },
  },
  {
    path: `${getForeignCompanyProfilePath()}:id`,
    exact: true,
    component: ForeignCompanyProfile,
    isBottomMenu: false,
    appBar: {
      title: <div>Spółka zagraniczna</div>,
      actionButtons: null,
    },
  },
  {
    path: `${getForeignCompanyManagementPath()}:id`,
    exact: true,
    component: ForeignCompanyManagement,
    isBottomMenu: false,
    appBar: {
      title: <div>Reprezentacja</div>,
      actionButtons: null,
    },
  },
  {
    path: `${getSettingsPath()}`,
    exact: true,
    component: UserSettings,
    isBottomMenu: false,
    appBar: {
      title: <div>Profil indywidualny</div>,
      actionButtons: null,
    },
  },
  {
    path: `${getPhoneVerificationPath()}`,
    exact: true,
    component: PhoneVerificationConfirmation,
    isBottomMenu: false,
    appBar: {
      title: <div>Weryfikacja</div>,
      actionButtons: null,
    },
  },
  // {
  //   path: `${getPaymentPath()}`,
  //   exact: true,
  //   component: Payment,
  //   isBottomMenu: false,
  // },
  // {
  //   path: `${getStatuePath()}`,
  //   exact: true,
  //   component: Statue,
  //   isBottomMenu: false,
  // },
  // {
  //   path: `${getInvestmentAgreementPath()}`,
  //   exact: true,
  //   component: InvestmentAgreement,
  //   isBottomMenu: false,
  // },
  // CREATING NEW PROFILE PATHS
  // {
  //   path: `${getProfileIdentitiesPath()}`,
  //   exact: true,
  //   component: ProfileIdentities,
  //   isBottomMenu: false,
  // },
  // {
  //   path: `${getProfileIdentitiesCreate()}`,
  //   exact: true,
  //   component: ProfileIdentitiesCreate,
  //   isBottomMenu: false,
  // },
  // {
  //   path: `${getProfileIdentitiesEdit()}`,
  //   exact: true,
  //   component: ProfileIdentitiesEdit,
  //   isBottomMenu: false,
  // },
];

const publicRoutes = [
  // {
  //   path: `${getCreateProfileWelcomePage()}`,
  //   exact: true,
  //   component: CreateProfileWelcomePage,
  //   isBottomMenu: true,
  // },
  {
    path: `${getHomePath()}`,
    exact: true,
    component: Home,
    isBottomMenu: false,
    excludedFromRoutingComponent: true,
  },
  // {
  //   path: `${getIntroPath()}`,
  //   exact: true,
  //   component: Intro,
  //   isBottomMenu: false,
  // },
  {
    path: `${getLoginPath()}`,
    exact: true,
    component: Login,
    isBottomMenu: false,
    label: 'Portfel',
    appBar: {
      title: <div>Transakcje</div>,
      actionButton: null,
    },
  },
  // {
  //   path: `${getPinPath()}`,
  //   exact: true,
  //   component: Pin,
  //   isBottomMenu: false,
  // },
  // {
  //   path: `${getNewPinPath()}`,
  //   exact: true,
  //   component: NewPin,
  //   isBottomMenu: false,
  // },
  //
  // // CREATING ACCOUNT PATHS
  {
    path: `${getRegisterPath()}`,
    exact: true,
    component: Register,
    isBottomMenu: false,
  },
  {
    path: `${getEmailVerificationPath()}`,
    exact: true,
    component: EmailVerificationConfirmation,
    isBottomMenu: false,
    appBar: {
      title: <div>Weryfikacja</div>,
      actionButtons: null,
    },
  },
  {
    path: `${getAccountActivationPathAlt()}`,
    exact: true,
    component: AccountActivation,
    isBottomMenu: false,
    appBar: {
      title: <div>Weryfikacja</div>,
      actionButtons: null,
    },
  },
  // alternative url
  {
    path: `${getEmailVerificationPathAlt()}`,
    exact: true,
    component: EmailVerificationConfirmation,
    isBottomMenu: false,
    appBar: {
      title: <div>Weryfikacja</div>,
      actionButtons: null,
    },
  },
  {
    path: `${getResetPasswordPath()}`,
    exact: true,
    component: ResetPassword,
    isBottomMenu: false,
  },
  {
    path: `${getRecoverPhoneNrPath()}`,
    exact: true,
    component: PhoneRecovery,
    isBottomMenu: false,
  },
  // {
  //   path: `${getResetNumberPath()}`,
  //   exact: true,
  //   component: ResetNumber,
  //   isBottomMenu: false,
  // },
  {
    path: `${getSetNewPasswordPath()}`,
    exact: true,
    component: SetNewPassword,
    isBottomMenu: false,
  },
  // alternative url
  {
    path: `${getSetNewPasswordPathAlt()}`,
    exact: true,
    component: SetNewPassword,
    isBottomMenu: false,
  },
  // {
  //   path: `${getActivationState()}`,
  //   exact: true,
  //   component: ActivationAccount,
  //   isBottomMenu: false,
  // },
  // {
  //   path: `${getResetPasswordState()}`,
  //   exact: true,
  //   component: ResetPasswordAccount,
  //   isBottomMenu: false,
  // },
];

export const getRoutesForPrivateRoutingComponent = () => {
  return privateRoutes;
  // return privateRoutes.reduce(
  //   (arr, elem) => (elem.childRoutes ? [...arr, elem, ...elem.childRoutes] : [...arr, elem]),
  //   []
  // );
};

export const getRoutesForPublicRoutingComponent = () => {
  return publicRoutes.filter((route) => !route.excludedFromRoutingComponent);
};

export const getRoutesForBottomNavigation = () =>
  privateRoutes.filter((route) => route.isBottomMenu);

export const getRoutesForDrawerNavigation = () =>
  privateRoutes.filter((route) => route.isDrawerMenu);
