import { createMuiTheme } from '@material-ui/core/styles';

export const theme = {
  colors: {
    mediumGrey: '#CCCCCC',
    lightGray: '#F0F0F0',
    grey: '#F0F0F0',
    darkGrey: '#A3A3A3',
    darkGray: '#A3A3A3',
    white: '#ffffff',
    red: '#FF3B30',
    green: '#34C759',
    yellow: '#F1B32C',
    dark: '#313131',
    blue: '#1977F2',
    black: '#000000',
    transparent: 'transparent',
  },
  header: {
    heightPxVal: 64,
    heightXSPxVal: 56,
  },
  footer: {
    heightPxVal: 64,
  },
  menu: {
    collapsedWidthPxVal: 60,
    bordersWidthPxVal: 5,
    expandedWidthPxVal: 240,
  },
  // import everywhere
  paddings: {
    appPaddingPxVal: 32,
    cardPaddingPxVal: 15,
    routeContentPxVal: 30,
  },
  responsive: {
    maxContentWidthPxVal: 768,
    maxSimpleModalWidthPxVal: 580
  },
  sizes: {
    buttonHeightPxVal: 52,
    inputHeightPxVal: 40,
    checkboxDimPxVal: 26,
    avatarDimPxVal: 33,
    navbarHeightPxVal: 64,
    navbarHeightSPxVal: 48,
    bottomBarHeightPxVal: 90,
  },
  devices: {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px',
  },
};

export const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: theme.colors.yellow,
    },
    secondary: { main: theme.colors.white },
    text: { primary: theme.colors.white },
  },
  // status: {},
  typography: {
    color: theme.colors.white,
    fontFamily: [
      'Open Sans',
      '-apple-system',
      'sans-serif',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    // },
    // overrides: {},
    // multilineColor: {
    //   color: theme.colors.creditDarkBlue,
  },
});

// based on https://jsramblings.com/how-to-use-media-queries-with-styled-components/
export const device = {
  mobileS: `(min-width: ${theme.devices.mobileS})`,
  mobileM: `(min-width: ${theme.devices.mobileM})`,
  mobileL: `(min-width: ${theme.devices.mobileL})`,
  tablet: `(min-width: ${theme.devices.tablet})`,
  laptop: `(min-width: ${theme.devices.laptop})`,
  laptopL: `(min-width: ${theme.devices.laptopL})`,
  desktop: `(min-width: ${theme.devices.desktop})`,
  desktopL: `(min-width: ${theme.devices.desktop})`,
};

// todo: better remove default export
export default theme;
