import React, { useEffect } from 'react';
import { HashRouter, BrowserRouter, Route, Switch } from 'react-router-dom';
import { MainContainer } from './App.styles';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from '@material-ui/styles';
import { materialTheme } from './theme';
import 'tailwindcss/dist/base.css';
import 'tailwindcss/dist/components.css';
import 'tailwindcss/dist/utilities.css';
import 'tailwindcss/dist/tailwind-dark.css';
import Landing from './views/Landing';
// import PrivateRoute from './views/PrivateRoute';
import SyncApp from './views/SyncApp';
import Layout from './views/Layout';
import PrivateRoute from './views/PrivateRoute';
import { getRoutesForPublicRoutingComponent } from './routes';
import ViewContainer from './components/RouteViewContainer';
import { RouteContent } from './views/Layout/Layout';
import { useScript } from './helpers/customHooks';
import { initFBScripts } from './helpers/initThirdScripts';
import { PublicAppBarExtension } from './components/PublicAppBarExtension';
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const App = () => {
  useScript('https://connect.facebook.net/en_US/sdk.js', initFBScripts);

  return (
    <div className="App">
      {/* todo: sw-snackbar <SWSnackbar />*/}
      <ThemeProvider theme={materialTheme}>
        <ToastContainer />
        <BrowserRouter>
          <Switch>
            <Route path="/" component={Landing} exact />
            {/*<Route exact path="/404" name="Page 404" component={Page404} />*/}
            {getRoutesForPublicRoutingComponent().map((route, key) => {
              const { component: Component, ...rest } = route;
              //todo: add wrapper
              return (
                <Route
                  key={key}
                  {...rest}
                  render={(props) => (
                    <ViewContainer>
                      <PublicAppBarExtension />
                      <RouteContent className="public-route-content">
                        <Component {...props} />
                      </RouteContent>
                    </ViewContainer>
                  )}
                />
              );
            })}
            <SyncApp>
              <PrivateRoute name="PrivateApp" component={Layout} />
            </SyncApp>
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
};

export default App;
