import Logo from '../assets/svg/old-do-not-import/placidlo_logo_white.svg';
import AppBarExtension from './AppBar/AppBarExtension';
import React from 'react';
import styled from 'styled-components';

export const publicAppBarExtensionHeight = '200px';

const PublicAppBarExtensionImg = styled.div`
  height: 200px;
  margin-bottom: 32px;
  padding-top: 80px;
  img {
    margin-left: auto;
    margin-right: auto;
  }
`;

export const PublicAppBarExtensionContent = () => (
  <PublicAppBarExtensionImg>
    <img src={Logo} />
  </PublicAppBarExtensionImg>
);

export const PublicAppBarExtension = (props) => {
  return (
    <AppBarExtension height={publicAppBarExtensionHeight}>
      <PublicAppBarExtensionContent />
    </AppBarExtension>
  );
};
