import Landing from './Landing';
import _ from 'lodash';
import { connect } from 'react-redux';
import { getAuthInfo, signInWithFacebook, signInWithGoogle } from '../../reducers/auth.reducer';
import { getAppConfigs } from '../../reducers/config.actions';

function mapStateToProps(state, otherProps) {
  return {
    auth: state.auth,
    loginErrorMessages: state.auth.loginErrorMessages,
    areConstantsLoaded: state.config?.constants && Object.keys(state.config?.constants).length > 0,
    areAuthInfoLoaded: state.auth.user?.profiles && _.isArray(state.auth.user?.profiles),
  };
}

export default connect(mapStateToProps, {
  signInWithFacebook,
  signInWithGoogle,
  getAuthInfo,
  getAppConfigs,
})(Landing);
