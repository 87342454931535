import { connect } from 'react-redux';
import Layout from './Layout';
import { getMenuBadges, signOut } from '../../reducers/config.actions';

function mapStateToProps(state, otherProps) {
  return {
    auth: state.auth,
    menuBadges: state?.config?.menuBadges,
  };
}

export default connect(mapStateToProps, { getMenuBadges, signOut })(Layout);
