import styled from "styled-components";

export const MainContainer = styled('div')`
  	min-height: 100vh;
`;

export const Overlay = styled('div')`
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 1;
	background: #000;
	opacity: 0.3;
	display: ${props => props.isVisible ? 'block' : 'none'};
`;

export const PopupWrapper = styled('div')`
	text-align: center;
    position: fixed;
    left: 16px;
    right: 16px;
    top: 50%;
    margin: auto;
    background-color: #fff;
    border-radius: 4px;
    max-height: auto;
    max-width: 343px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 32px 24px;
    transform: translate(0, -50%);
    -webkit-overflow-scrolling: touch;
    z-index: 3;
`;
