import React from 'react';
import styled from 'styled-components';
import { theme } from '../theme';

export const ViewContainer = styled.div`
  height: 100vh;
  width: 100vw;
  overflow: overlay;
  margin: 0;
  padding: 0;
  position: relative;
`;

export default ViewContainer;
