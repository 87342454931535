import axios from 'axios';
import { getSessionTokens, saveSessionTokens } from './utilities';
import { getLoginPath } from '../routes';
import { unauthUser } from '../reducers/auth.reducer';

const baseUrl = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = baseUrl;

export const isIgnore401 = () => {
  const parameter = process.env.REACT_APP_IGNORE_401;
  return parameter && parameter === 'true';
};

export default class NetworkService {
  static interceptor401;

  static setupDefaultHeaders(token) {
    if (token) axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  static resetDefaultHeaders(token) {
    axios.defaults.headers.common = {};
  }

  static syncHeaderTokenWithStorage() {
    const token = getSessionTokens()?.sessionToken;
    if (token && token.length > 1) this.setupDefaultHeaders(token);
  }

  static async refreshAccessToken({ refreshToken }) {
    return await axios.post(`${baseUrl}Users/jwt-token/refresh/`, {
      refresh: refreshToken,
    });
  }

  static setupInterceptors(store, history) {
    // Add a response interceptor
    this.interceptor401 = axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        let originalRequest = error.config;

        if (+error.response.status === 401 && !isIgnore401() && !originalRequest._retry) {
          originalRequest._retry = true;
          const oldRefreshToken = store.auth?.refreshToken || getSessionTokens().refreshToken;
          axios.interceptors.request.eject(this.interceptor401);
          // todo: must retry failed 401 request with redux
          // debugger
          NetworkService.refreshAccessToken({
            refreshToken: oldRefreshToken,
          })
            .then((tokenResponse) => {
              // update tokens
              const newTokens = tokenResponse?.data;
              if (newTokens && newTokens.access && newTokens.refresh) {
                saveSessionTokens({
                  refreshToken: newTokens.refresh,
                  accessToken: newTokens.access,
                });
                NetworkService.syncHeaderTokenWithStorage();
                NetworkService.setupInterceptors(store, history);
              } else {
                localStorage.clear();
                NetworkService.resetDefaultHeaders();
                store.dispatch(unauthUser());
                history.push(getLoginPath());
              }
              const retryRequest = () => {
                originalRequest = {
                  ...originalRequest,
                  headers: {
                    ...originalRequest.headers,
                    Authorization: `Bearer ${newTokens.access}`,
                  },
                };
                axios(originalRequest)
                  .then((data) => {
                    Promise.resolve(data);
                  })
                  .catch((e) => {
                    console.log('e', e);
                    Promise.reject(e);
                  });
              };
              retryRequest();
            })
            .catch((err) => {
              localStorage.clear();
              NetworkService.resetDefaultHeaders();
              store.dispatch(unauthUser());
              history.push(getLoginPath());
            });
        }
        return Promise.reject(error);
      }
    );
  }
}
