export const WalletIcon = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="27.429"
      height="24"
      viewBox="0 0 30 30"
      fill="#fff"
    >
      <path
        fill="#fill"
        d="M24.707 5.143H4.286a.857.857 0 110-1.714h20.571a.857.857 0 00.857-.857A2.571 2.571 0 0023.143 0H3.429A3.428 3.428 0 000 3.429v17.142A3.428 3.428 0 003.429 24h21.278a2.652 2.652 0 002.721-2.571V7.714a2.652 2.652 0 00-2.721-2.571zm-2.421 11.143A1.714 1.714 0 1124 14.571a1.714 1.714 0 01-1.714 1.715z"
      />
    </svg>
  );
};

export const CoinsIcon = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 25 30"
      fill="#fff"
    >
      <path
        fill="#fill"
        d="M0,19v2c0,1.655,4.031,3,9,3s9-1.345,9-3V19c-1.936,1.364-5.475,2-9,2S1.936,20.362,0,19ZM15,6c4.969,0,9-1.345,9-3s-4.031-3-9-3S6,1.345,6,3,10.031,6,15,6ZM0,14.081V16.5c0,1.655,4.031,3,9,3s9-1.345,9-3V14.081C16.064,15.675,12.52,16.5,9,16.5S1.936,15.675,0,14.081Zm19.5.516c2.686-.52,4.5-1.486,4.5-2.6V10a11.511,11.511,0,0,1-4.5,1.617ZM9,7.5c-4.969,0-9,1.678-9,3.75S4.031,15,9,15s9-1.678,9-3.75S13.969,7.5,9,7.5Zm10.28,2.639C22.092,9.633,24,8.639,24,7.5v-2c-1.664,1.177-4.523,1.809-7.533,1.959A5.249,5.249,0,0,1,19.28,10.139Z"
      />
    </svg>
  );
};

export const SafeIcon = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="24"
      viewBox="0 0 27 30"
      fill="#fff"
    >
      <g transform="translate(427 -821)">
        <rect className="a" width="24" height="24" rx="3.5" transform="translate(-425 821)" />
        <path
          fill="#fill"
          className="a"
          d="M-424.5 835h1v5h-1a2.5 2.5 0 01-2.5-2.5 2.5 2.5 0 012.5-2.5zM-424.5 826h1v5h-1a2.5 2.5 0 01-2.5-2.5 2.5 2.5 0 012.5-2.5z"
        />
        <circle cx="10" cy="10" r="10" transform="translate(-423 823)" fill="#313131" />
        <path
          fill="#fill"
          className="a"
          d="M-407.409 834.442l-1.031-.6a4.66 4.66 0 000-1.7l1.031-.6a.292.292 0 00.133-.339 6.026 6.026 0 00-1.323-2.289.291.291 0 00-.358-.056l-1.031.6a4.566 4.566 0 00-1.471-.849v-1.181a.29.29 0 00-.227-.283 6.082 6.082 0 00-2.642 0 .29.29 0 00-.227.283v1.19a4.71 4.71 0 00-1.471.849l-1.028-.6a.287.287 0 00-.358.056 5.989 5.989 0 00-1.323 2.289.289.289 0 00.133.339l1.031.6a4.66 4.66 0 000 1.7l-1.031.6a.292.292 0 00-.133.339 6.026 6.026 0 001.323 2.289.291.291 0 00.358.056l1.031-.6a4.566 4.566 0 001.471.849v1.19a.29.29 0 00.227.283 6.082 6.082 0 002.642 0 .29.29 0 00.227-.283v-1.193a4.71 4.71 0 001.471-.849l1.031.6a.287.287 0 00.358-.056 5.989 5.989 0 001.323-2.289.3.3 0 00-.136-.345zm-5.6.491a1.935 1.935 0 111.937-1.932 1.938 1.938 0 01-1.936 1.932z"
        />
      </g>
    </svg>
  );
};

export const InvestmentsIcon = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="24"
      viewBox="0 0 33 30"
      fill="#fff"
    >
      <path
        fill="#fill"
        d="M31 20H4V1a1 1 0 00-1-1H1a1 1 0 00-1 1v21a2 2 0 002 2h29a1 1 0 001-1v-2a1 1 0 00-1-1zM29 2h-7.379a1.5 1.5 0 00-1.061 2.561l2.025 2.025L18 11.172l-4.586-4.586a2 2 0 00-2.828 0l-4.293 4.293a1 1 0 000 1.414l1.414 1.414a1 1 0 001.414 0L12 10.828l4.586 4.586a2 2 0 002.828 0l6-6 2.025 2.025A1.5 1.5 0 0030 10.378V3a1 1 0 00-1-1z"
      />
    </svg>
  );
};

export const NotificationsIcon = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="24"
      viewBox="0 0 30 30"
      fill="#fff"
    >
      <path
        fill="#fill"
        d="M10.501 24a3 3 0 003-3h-6a3 3 0 003 3zm10.1-7.018c-.91-.973-2.6-2.437-2.6-7.232a7.4 7.4 0 00-6-7.273V1.5a1.5 1.5 0 10-3 0v.977a7.4 7.4 0 00-6 7.273c0 4.8-1.695 6.259-2.6 7.232A1.465 1.465 0 00.001 18a1.5 1.5 0 001.5 1.5h17.995a1.5 1.5 0 001.505-1.5 1.464 1.464 0 00-.4-1.018z"
      />
    </svg>
  );
};
