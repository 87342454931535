import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../helpers/RequestStatus';
import { showErrorMessagesForAction } from '../helpers/ApiRequest';

const slice = createSlice({
  name: 'wallet',
  initialState: {
    fetchStatus: null,
    errorMessages: null,
    details: {},
  },
  reducers: {},
  // don't have actions that can be exported, only respond to other
  extraReducers: {},
});

export const {} = slice.actions;

export default slice.reducer;
