import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import ApiRequest from '../helpers/ApiRequest';

// Investments //

export const getInvestmentsList = createAsyncThunk(
  'investments/getList',
  async (data, thunkAPI) => {
    const { search } = data;
    const response = await axios.get(`/Investments/${search ? `?name=${search}` : ''}`);
    return response.data;
  }
);

export const getInvestmentDetails = createAsyncThunk(
  'investments/getDetails',
  async (data, thunkAPI) => {
    const { investmentId } = data;
    const response = await axios.get(`/Investments/${investmentId}/`);
    return response.data;
  }
);

// End of Investments //

// InvestmentMessages //

export const getInvestmentMessages = createAsyncThunk(
  'investments/getMessages',
  async (data, thunkAPI) => {
    const { investmentId } = data;
    const response = await axios.get(
      `/InvestmentMessages/?is_active=true&investment=${investmentId}`
    );
    return response.data;
  }
);

// End of InvestmentMessages //

// InvestmentCharts //

export const getInvestmentChartBalanceForecasts = createAsyncThunk(
  'investments/getChartBalanceForecasts',
  async (data, thunkAPI) => {
    const { investmentId, reqParams = '' } = data;
    const response = await axios.get(
      `/InvestmentChartBalanceForecasts/?investment=${investmentId}${
        reqParams ? `&${reqParams}` : ''
      }`
    );
    return response.data;
  }
);

export const getInvestmentChartBalanceValuations = createAsyncThunk(
  'investments/getChartBalanceValuations',
  async (data, thunkAPI) => {
    const { investmentId, reqParams = '' } = data;
    const response = await axios.get(
      `/InvestmentChartBalanceValuations/?investment=${investmentId}${
        reqParams ? `&${reqParams}` : ''
      }`
    );
    return response.data;
  }
);

// End of InvestmentCharts //

// InvestmentCalculator //

export const getInvestmentCalculation = createAsyncThunk(
  'investments/getCalculation',
  (data, thunkAPI) => ApiRequest.post('/InvestmentCalculator/', data)(data, thunkAPI)
);

// End of InvestmentCalculator //

// InvestmentDocuments //

export const getInvestmentDocuments = createAsyncThunk(
  'investments/getDocuments',
  async (data, thunkAPI) => {
    const { investmentId } = data;
    const response = await axios.get(
      `/InvestmentDocuments/?is_active=true&investment=${investmentId}`
    );
    return response.data;
  }
);

export const downloadInvestmentDocument = createAsyncThunk(
  'InvestmentDocument/download',
  async (data, thunkAPI) => {
    await axios({
      url: `${process.env.REACT_APP_API_URL}/InvestmentDocuments/download/${data.id}/`,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${data.documentTitle}`);
      document.body.appendChild(link);
      link.click();
    });
  }
);

// End of InvestmentDocuments //

// InvestmentAgreements //

export const getInvestmentAgreements = createAsyncThunk(
  'investments/getAgreements',
  async (data, thunkAPI) => {
    const { investmentId } = data;
    const response = await axios.get(
      `/InvestmentAgreements/?is_active=true&investment=${investmentId}`
    );
    return response.data;
  }
);

export const investmentAgreementAcceptation = createAsyncThunk(
  'investments/investmentAgreementAcceptation',
  async (data, thunkAPI) => {
    const response = await axios.post(`/InvestmentAgreements/acceptation/`, data);
    return response.data;
  }
);

export const checkInvestmentAgreementAcceptation = createAsyncThunk(
  'investments/checkInvestmentAgreementAcceptation',
  async (data, thunkAPI) => {
    const response = await axios.post(`/InvestmentAgreements/check_acceptation/`, data);
    return response.data;
  }
);

export const downloadInvestmentAgreement = createAsyncThunk(
  'InvestmentDocument/download',
  async (data, thunkAPI) => {
    await axios({
      url: `${process.env.REACT_APP_API_URL}InvestmentAgreements/download/`,
      method: 'POST',
      responseType: 'blob',
      data: {
        investment_agreement: data.id,
        profile: data.profileId,
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${data.documentTitle}`);
      document.body.appendChild(link);
      link.click();
    });
  }
);

export const downloadInvestmentAgreementsZipped = createAsyncThunk(
  'InvestmentDocument/download',
  async (data, thunkAPI) => {
    await axios({
      url: `${process.env.REACT_APP_API_URL}InvestmentAgreements/zipped/`,
      method: 'POST',
      responseType: 'arraybuffer',
      data: {
        investment: data.id,
        profile: data.profileId,
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${data.documentTitle}`);
      document.body.appendChild(link);
      link.click();
    });
  }
);

// End of InvestmentAgreements //
