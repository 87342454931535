import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { theme } from '../../theme';
import CloseIcon from '@material-ui/icons/Close';
import HamburgerIcon from '../../assets/svg/hamburger-menu.svg';

export const useStyles = makeStyles((materialTheme) => ({
  root: {
    flexGrow: 1,
    maxWidth: '100vw',
    maxHeight: '64px',
  },
  rightActionButtonRoot: {
    paddingRight: 0,
  },
  menuButton: {
    // marginRight: materialTheme.spacing(2),
  },
}));

const AppBarContainer = styled.div`
  max-height: ${theme.header.heightPxVal}px;

  .MuiAppBar-root {
    min-height: ${theme.header.heightPxVal}px;
  }
  .MuiToolbar-root {
    padding-left: 0px;
    padding-right: 0px;
    //background-color: ${theme.colors.sth};

    .MuiTypography-h6,
    .MuiButton-label {
      //color: ${theme.colors.sth};
    }
  }
  .MuiAppBar-positionFixed {
    box-shadow: none !important;
    padding-left: ${theme.paddings.appPaddingPxVal}px;
    padding-right: ${theme.paddings.appPaddingPxVal}px;

    .MuiToolbar-root {
      width: 100%;
      max-width: ${theme.responsive.maxContentWidthPxVal}px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const AppBarContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  flex-grow: 1;
  color: ${theme.colors.white};

  & div:first-child {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  & div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & div:last-child {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

const Title = styled.div`
  flex-grow: 1;
  font-size: 1.8rem;
  font-style: normal;
  font-family: 'Open Sans';
  font-weight: bold;
  text-align: center;
`;

const StyledIconButton = styled(IconButton)`
  * {
    // color: ${theme.colors.darkBrown};
  }
`;

const ActionsContainer = styled.div``;

const ActionButtonsContainer = styled.div`
  .MuiButton-label {
    color: ${theme.colors.white};
  }
`;

function CustomAppBar(props) {
  const {
    title = 'Title',
    actionButtons = <div></div>,
    clickRoute,
    history,
    setIsMenuDrawerOpen,
  } = props;

  const classes = useStyles();

  const onMenuClick = () => {
    clickRoute ? history.push(clickRoute) : setIsMenuDrawerOpen(true);
  };

  const onBackClick = () => {
    history.goBack();
  };

  return (
    <AppBarContainer className={classes.root}>
      <AppBar position="fixed">
        <Toolbar className="toolbar-container">
          <AppBarContent>
            <div>
              <StyledIconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={onMenuClick}
              >
                <img src={HamburgerIcon} alt={'menu'} />
              </StyledIconButton>
            </div>

            <Title>{title}</Title>

            <ActionButtonsContainer>{actionButtons}</ActionButtonsContainer>
          </AppBarContent>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </AppBarContainer>
  );
}

export default withRouter(CustomAppBar);
