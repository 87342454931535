import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../helpers/RequestStatus';
import { isRejectedWithErrors, showErrorMessagesForAction } from '../helpers/ApiRequest';
import { getAuthInfo } from './auth.reducer';
import {
  postProfile,
  putProfile,
  patchProfile,
  postProfileMemberships,
  patchProfileMemberships,
  postPutProfileMemberships,
} from './profiles.actions';
import { defaultProfileType, profileTypes } from '../constants';
import { toast } from 'react-toastify';

const onPatchProfileReject = ({ state, action }) => {
  state.errorMessages = action.payload;
  state.postPutFetchStatus = RequestStatus.status.ERROR;
  toast.error('Nie udało się zapisać danych');
};

const slice = createSlice({
  name: 'profiles',
  initialState: {
    fetchStatus: null,
    postPutFetchStatus: null,
    errorMessages: null,
    companiesPolandErrorMessages: null,
    companiesForeignErrorMessages: null,
    individual: {},
    companiesPoland: [],
    companiesForeign: [],
    lastPostPutProfile: {},
    listAll: [],
  },
  reducers: {
    updatePostPutFetchStatus(state, action) {
      state.postPutFetchStatus = action.payload;
    },
    updateLastPostPutProfile(state, action) {
      state.lastPostPutProfile = action.payload || {};
    },
    clearErrorMessages(state, action) {
      state.errorMessages = null;
    },
  },
  // don't have actions that can be exported, only respond to other
  extraReducers: {
    [getAuthInfo.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getAuthInfo.fulfilled]: (state, action) => {
      state.listAll = action.payload.results;
      if (action.payload.results?.length > 0) {
        const defaultProfile = action.payload.results.find(
          (profile) => profile.profile_type === defaultProfileType
        );
        const polishCompanyProfiles = action.payload.results.filter(
          (profile) => profile.profile_type === profileTypes.companyPoland
        );
        const foreignCompanyProfiles = action.payload.results.filter(
          (profile) => profile.profile_type === profileTypes.companyForeign
        );
        if (defaultProfile) state.individual = defaultProfile;
        state.companiesPoland = polishCompanyProfiles;
        state.companiesForeign = foreignCompanyProfiles;
      }
      state.fetchStatus = RequestStatus.status.DONE;
    },
    [getAuthInfo.rejected]: (state, action) => {
      state.fetchStatus = RequestStatus.status.ERROR;
    },
    [postProfile.pending]: (state, action) => {
      state.postPutFetchStatus = RequestStatus.status.FETCHING;
    },
    [postProfile.fulfilled]: (state, action) => {
      const newProfile = action.payload;
      state.errorMessages = null;
      state.fetchStatus = RequestStatus.status.DONE;
      state.lastPostPutProfile = newProfile;
      toast.success('Zaktualizowano pomyślnie');
      if (newProfile.profile_type === profileTypes.individual) state.individual = action.payload;
      else if (newProfile.profile_type === profileTypes.companyPoland) {
        state.companiesPoland = [...state.companiesPoland, newProfile];
        state.listAll = [...state.listAll, newProfile];
      } else if (newProfile.profile_type === profileTypes.companyForeign) {
        state.companiesForeign = [...state.companiesForeign, newProfile];
        state.listAll = [...state.listAll, newProfile];
      }
      state.postPutFetchStatus = RequestStatus.status.DONE;
    },
    [postProfile.rejected]: (state, action) => {
      state.errorMessages = action.payload;
      state.postPutFetchStatus = RequestStatus.status.ERROR;
      toast.error('Nie udało się zapisać danych');
    },
    [patchProfile.pending]: (state, action) => {
      state.postPutFetchStatus = RequestStatus.status.FETCHING;
    },
    [patchProfile.fulfilled]: (state, action) => {
      if (isRejectedWithErrors(action)) {
        onPatchProfileReject({ state, action });
        return;
      }
      const newProfile = action.payload;
      state.errorMessages = null;
      state.fetchStatus = RequestStatus.status.DONE;
      state.lastPostPutProfile = newProfile;
      toast.success('Zaktualizowano pomyślnie');
      if (newProfile.profile_type === profileTypes.individual) state.individual = action.payload;
      else if (newProfile.profile_type === profileTypes.companyPoland) {
        let companies = [...state.companiesPoland];
        let oldProfileIdx = companies.findIndex(
          (company) => parseInt(company.id) === newProfile.id
        );
        companies[oldProfileIdx] = newProfile;
        state.companiesPoland = companies;
        state.listAll = [state.individual, ...state.companiesForeign, ...companies];
      } else if (newProfile.profile_type === profileTypes.companyForeign) {
        let companies = [...state.companiesForeign];
        let oldProfileIdx = companies.findIndex(
          (company) => parseInt(company.id) === newProfile.id
        );
        companies[oldProfileIdx] = newProfile;
        state.companiesForeign = companies;
        state.listAll = [state.individual, ...state.companiesPoland, ...companies];
      }
      state.postPutFetchStatus = RequestStatus.status.DONE;
    },
    [patchProfile.rejected]: (state, action) => {
      onPatchProfileReject({ state, action });
    },
    [putProfile.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.status.FETCHING;
    },
    [putProfile.fulfilled]: (state, action) => {
      state.individual = action.payload;
      state.errorMessages = null;
      state.fetchStatus = RequestStatus.status.DONE;
      toast.success('Zaktualizowano pomyślnie');
      state.postPutFetchStatus = RequestStatus.status.DONE;
    },
    [putProfile.rejected]: (state, action) => {
      state.errorMessages = action.payload;
      state.fetchStatus = RequestStatus.status.ERROR;
      toast.error('Nie udało się zapisać danych');
    },
    [postProfileMemberships.pending]: (state, action) => {},
    [postProfileMemberships.fulfilled]: (state, action) => {},
    [postProfileMemberships.rejected]: (state, action) => {},
    [patchProfileMemberships.pending]: (state, action) => {},
    [patchProfileMemberships.fulfilled]: (state, action) => {},
    [patchProfileMemberships.rejected]: (state, action) => {
      toast.error('Nie udało się zapisać danych');
    },
    [postPutProfileMemberships.pending]: (state, action) => {
      state.postPutFetchStatus = RequestStatus.status.FETCHING;
    },
    // catch axios.all errors
    [postPutProfileMemberships.fulfilled]: (state, action) => {
      const isErrorResponse =
        _.isArray(action.payload) &&
        action.payload.find((element) => element?.message === 'Rejected');
      if (isErrorResponse) {
        toast.error('Nie udało się zaktualizować danych');
      }
      state.postPutFetchStatus = RequestStatus.status.DONE;
    },
    [postPutProfileMemberships.rejected]: (state, action) => {
      state.postPutFetchStatus = RequestStatus.status.ERROR;
      toast.error('Nie udało się zapisać danych');
    },
  },
});

export const {
  updatePostPutFetchStatus,
  updateLastPostPutProfile,
  clearErrorMessages,
} = slice.actions;

export default slice.reducer;
