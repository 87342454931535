import React from 'react';
import styled from 'styled-components';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Close from '@material-ui/icons/Close';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import { theme } from '../../theme';
import { makeStyles } from '@material-ui/core/styles';
import { getRoutesForDrawerNavigation } from '../../routes';
import { useHistory } from 'react-router-dom';

const TopButtonContainer = styled.div``;
const LogoContainer = styled.div`
  margin-top: 120px;
  margin-bottom: 60px;
`;
const MenuListContainer = styled.div``;

const Logo = styled.img``;

const DrawerContent = styled.div`
  @media (min-width: ${theme.responsive.maxContentWidthPxVal}px) {
    margin-left: ${(props) => props.wideScreenPadding}px;
  }
`;

const useStyles = makeStyles((materialTheme) => ({
  root: {
    zIndex: `9990 !important`,
  },
  paper: {
    backgroundColor: theme.colors.yellow,
    color: theme.colors.white,
    // fontSize: '18px',
    fontWeight: 400,
    width: '100vw',
    maxWidth: `${theme.responsive.maxContentWidthPxVal}px`,
    paddingLeft: theme.paddings.appPaddingPxVal,
    paddingRight: theme.paddings.appPaddingPxVal,
    paddingTop: 0,
    [`@media (min-width: ${theme.responsive.maxContentWidthPxVal}px)`]: {
      maxWidth: 'unset',
    },
  },
  closeButtonRoot: {
    paddingLeft: 8,
  },
  listItemRoot: {
    paddingLeft: 0,
  },
  title: {
    color: theme.colors.white,
    flexGrow: 1,
    textAlign: 'center',
  },
}));

const MenuDrawer = (props) => {
  const { isMenuDrawerOpen, setIsMenuDrawerOpen, signOut } = props;

  const classes = useStyles();
  const history = useHistory();

  const logout = { label: 'LOG OUT', action: signOut };
  const menuOptions = [...getRoutesForDrawerNavigation(), logout];

  const handleMenuItemClick = (item) => {
    setIsMenuDrawerOpen(false);
    setTimeout(() => {
      if (item.action) {
        item.action();
      } else if (item.path) history.push(item.path);
    }, 100);
  };

  const getWideScreenPadding = () => {
    const toolbarContainer = document.querySelector(`.toolbar-container`);
    return (
      toolbarContainer &&
      toolbarContainer.offsetLeft > theme.paddings.appPaddingPxVal &&
      toolbarContainer.offsetLeft - theme.paddings.appPaddingPxVal
    );
  };

  return (
    <SwipeableDrawer
      anchor="left"
      open={isMenuDrawerOpen}
      onClose={() => setIsMenuDrawerOpen(false)}
      onOpen={() => setIsMenuDrawerOpen(true)}
      classes={{ root: classes.root, paper: classes.paper }}
    >
      <DrawerContent wideScreenPadding={isMenuDrawerOpen && getWideScreenPadding()}>
        <TopButtonContainer>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => setIsMenuDrawerOpen(false)}
            classes={{ root: classes.closeButtonRoot }}
          >
            <Close fontSize="large" />
          </IconButton>
        </TopButtonContainer>

        <LogoContainer>
        </LogoContainer>

        <MenuListContainer>
          <List>
            {menuOptions.map((item, idx) => (
              <ListItem button key={idx} classes={{ root: classes.listItemRoot }}>
                <ListItemText
                  primary={item.label}
                  onClick={(event) => handleMenuItemClick(item, event)}
                />
              </ListItem>
            ))}
          </List>
        </MenuListContainer>
      </DrawerContent>
    </SwipeableDrawer>
  );
};

export default MenuDrawer;
