import React from 'react';
import styled from 'styled-components';
import { default as MaterialButton } from '@material-ui/core/Button';
import { theme } from '../theme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Link } from 'react-router-dom';
import { LinkedCamera } from '@material-ui/icons';

const useStyles = (props) =>
  makeStyles({
    root: {
      backgroundColor: props.cancel ? theme.colors.lightGray : theme.colors.yellow,
      width: props.small ? '140px' : '100%',
      maxWidth: '200px',
      display: 'inline-block',
      '&:hover': {
        background: theme.colors.yellow,
        color: theme.colors.white,
      },
      color: props.cancel ? theme.colors.yellow : theme.colors.white,
      textAlign: 'center',
      borderRadius: '40px',
      fontSize: '1.6rem',
      maxWidth: '200px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  });

// const StyledLink = styled(Link)`
//   background-color: ${theme.colors.yellow};
//   color: ${theme.colors.white};
//   text-align: center;
//   border-radius: 40px;
//   font-size: 1.6rem;
//   max-width: 200px;
//   margin-left: auto;
//   margin-right: auto;
// `;

export const Button = ({ children, ...props }) => {
  const classes = useStyles(props)();
  return (
    <MaterialButton classes={{ root: classes.root }} {...props}>
      {children}
    </MaterialButton>
  );
};

const useButtonLinkStyles = makeStyles({
  root: {
    backgroundColor: theme.colors.yellow,
    width: '100%',
    textTransform: 'capitalize',
    maxWidth: ({ maxWidth }) => maxWidth || '200px',
    display: 'inline-block',
    '&:hover': {
      background: theme.colors.yellow,
      color: theme.colors.white,
      textDecoration: 'none',
      cursor: 'pointer',
    },
    color: theme.colors.white,
    textAlign: 'center',
    borderRadius: '40px',
    fontSize: '1.6rem',
    fontWeight: 'bold',
    padding: '0.7rem 0px',
    marginBottom: '1.6rem',
  },
});

export const ButtonLink = (props) => {
  const { to, children } = props;
  const classes = useButtonLinkStyles(props);
  return (
    <Link to={to}>
      <MaterialButton classes={{ root: classes.root }}>{children}</MaterialButton>
    </Link>
  );
};

const useSocialMediaStyles = makeStyles({
  root: {
    backgroundColor: theme.colors.blue,
    textTransform: 'capitalize',
    color: theme.colors.white,
    borderRadius: '40px',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: '1.6rem',
    width: '240px',
    padding: '0.8rem 10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',

    '& span.text': {
      display: 'block',
      width: '100%',
      textAlign: 'center',
    },

    '&:hover': {
      background: theme.colors.blue,
      color: theme.colors.white,
      textDecoration: 'none',
      cursor: 'pointer',
    },
  },
});

export const SocialMediaButton = (props) => {
  const { children } = props;
  const classes = useSocialMediaStyles();
  return (
    <MaterialButton {...props} classes={{ root: classes.root }}>
      {children}
    </MaterialButton>
  );
};

const TextLinkContainer = styled.div`
  color: ${theme.colors.yellow};
  margin-top: 32px;
`;

export const TextLink = (props) => {
  const { to, children } = props;
  return (
    <Link to={to}>
      <TextLinkContainer>{children}</TextLinkContainer>
    </Link>
  );
};
